import cn from 'classnames'
import { useCallback } from 'react'
import forEach from 'lodash/forEach'
import isArray from 'lodash/isArray'
import isObject from 'lodash/isObject'
import { createUseStyles } from '../../helpers/createStyles'
import {
  closeShopFiltersDialog,
  getSiteData,
  isShopFiltersDialogOpen
} from '../../store/layoutSlice'
import useSnapshot from '../../store/useSnapshot'
import {
  sortModes,
  getSortMode,
  setSortMode,
  getShopFilters,
  getShopFiltersResultCount,
  clearShopFilters
} from '../../store/shopFiltersSlice'
import { layouts, setLayout, getLayout } from '../../store/productListing'
import { theme } from '../../styles/theme'
import ModalDialog from '../Dialog/ModalDialog'
import Section from '../Section'
import Button from '../Button'
import DialogHeader from '../Dialog/DialogHeader'
import FilterOption from './FilterOption'
import SimpleFilterOption from './SimpleFilterOption'
import StockFilter from './StockFilter'
import UnitSystemToggle from './UnitSystemToggle'
// import PriceRangeFilter from './PriceRangeFilter'
import RoomStyleFilter from './RoomStyleFilter'
import isEmpty from 'lodash/isEmpty'
import getFilters from '../../helpers/getFilters'

const isEmptyObject = (obj) => {
  let empty = true
  forEach(obj, (value) => {
    if (isArray(value)) {
      empty = empty && isEmpty(value)
    } else if (isObject(value)) {
      empty = empty && isEmptyObject(value)
    } else {
      if (typeof (value) === 'number' && (value !== 0 && value !== 20000)) {
        empty = empty && false
      } else {
        empty = empty && isEmpty(value)
      }
    }
  })
  return empty
}

export const isShopFilterEmpty = (snap) => {
  const filters = getShopFilters(snap)
  return isEmptyObject(filters)
}

export default function ShopFiltersDialog () {
  const styles = useStyles()
  const snap = useSnapshot()
  const open = isShopFiltersDialogOpen(snap)
  const shopListingLayout = getLayout(snap)
  const activeSortMode = getSortMode(snap)
  const siteData = getSiteData(snap)
  const colorLabel = siteData?.colorLabel || 'Color'
  const fibreLabel = siteData?.fiberLabel || 'Fibre'
  const sizeLabel = siteData?.sizeLabel || 'Size'
  const filterState = getShopFilters(snap)
  const filterOptions = getFilters()

  const filterResultCount = getShopFiltersResultCount(snap)

  const onClose = useCallback(() => {
    closeShopFiltersDialog()
  }, [])

  const clearFilters = useCallback(() => {
    clearShopFilters()
  }, [])

  const switchLayout = useCallback((mode) => {
    setLayout(mode)
  }, [])

  const setSort = useCallback((mode) => {
    setSortMode(mode)
  }, [])

  const { shopFilterLabel = 'Filter & Sort' } = getSiteData(snap)

  // const mounted = useMounted()

  return (
    <ModalDialog
      open={open}
      onClose={onClose}
      colorTheme={theme.colors.pageTheme.dove}
      size='xxs'
      hideOverlayAtDesktop
      className={cn(open && styles.dialog)}
      footer={(
        <div className={styles.footer}>
          <button onClick={clearFilters} className={styles.clearAllButton}>Clear All</button>
          <Button onClick={onClose} className={styles.applyButton}>Apply</Button>
        </div>
    )}
    >
      <Section className={styles.container} noBottomMargin>
        <DialogHeader>
          <h4>
            {shopFilterLabel}<span className={styles.count}>{filterResultCount}</span>
          </h4>
        </DialogHeader>
        <div className={styles.modalBody}>
          <div className={styles.topControls}>
            <div className={styles.viewModeControl}>
              <span>View</span>
              {layouts && layouts.map(layout => (
                <button
                  key={layout.mode}
                  className={cn(styles.layoutOption, { active: shopListingLayout === layout.mode })}
                  onClick={() => switchLayout(layout.mode)}
                >
                  {layout.icon}
                </button>
              ))}
            </div>
            <div className={styles.sortModeControl}>
              <span>Sort by</span>
              {sortModes && sortModes.map(sortMode => (
                <button
                  key={sortMode.value}
                  className={cn(styles.sortOption, { active: activeSortMode === sortMode.value })}
                  onClick={() => setSort(sortMode.value)}
                >
                  {sortMode.label}
                </button>
              ))}
            </div>
          </div>
          <div className={styles.filterOptions}>
            <StockFilter label='In stock' filterState={filterState} />
            {!isEmpty(filterOptions.room) && (
              <FilterOption
                label='Room'
                filterState={filterState}
                options={filterOptions.room}
                stateKey='room'
              />
            )}
            {!isEmpty(filterOptions.roomStyle) && (
              <RoomStyleFilter
                filterState={filterState}
                options={filterOptions.roomStyle}
              />
            )}
            <FilterOption
              label={colorLabel}
              filterState={filterState}
              options={filterOptions.colour}
              stateKey='colour'
            />
            {!isEmpty(filterOptions.fibre) && (
              <FilterOption
                label={fibreLabel}
                filterState={filterState}
                options={filterOptions.fibre}
                stateKey='fibre'
              />
            )}
            <SimpleFilterOption
              label={sizeLabel}
              filterState={filterState}
              options={filterOptions.size}
              stateKey='size'
            />
            {/* <PriceRangeFilter
              minPriceBound={0}
              maxPriceBound={20000}
            /> */}
            {!isEmpty(filterOptions.suitability) && (
              <FilterOption
                label='Suitability'
                filterState={filterState}
                options={filterOptions.suitability}
                stateKey='suitability'
              />
            )}
            {!isEmpty(filterOptions.collection) && (
              <FilterOption
                label='Collection'
                filterState={filterState}
                options={filterOptions.collection}
                stateKey='collection'
              />
            )}
            <UnitSystemToggle label='Units' />
          </div>
        </div>
      </Section>
    </ModalDialog>
  )
  // ), document.getElementById('filter-dialog'))
}

const useStyles = createUseStyles({
  dialog: {
    // boxShadow: '0px -10px 20px rgba(49, 41, 36, 0.1)'
  },
  container: {
    // ...gridStyle(4),
    paddingBottom: theme.spacingPx(2),
    overflow: 'auto',
    [theme.breakpoints.up('md')]: {
      margin: `0 ${theme.spacing(5)}px !important`,
      paddingTop: theme.spacing(5)
    }
  },
  count: {
    fontSize: 14,
    verticalAlign: 'top',
    display: 'inline-block',
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(0.5),
    fontFamily: theme.fonts.body
  },
  footer: {
    backgroundColor: theme.colors.pageTheme.dove.background,
    minHeight: 64,
    display: 'flex',
    alignItems: 'center',
    margin: `0 ${theme.gutter.sm}px`,
    padding: `${theme.spacing(1)}px 0`,
    [theme.breakpoints.up('md')]: {
      margin: `0 ${theme.spacing(5)}px`,
      padding: `${theme.spacing(4)}px 0`
    }
  },
  clearAllButton: {
    paddingLeft: '0',
    paddingRight: theme.spacingPx(2),
    paddingTop: theme.spacingPx(2),
    paddingBottom: theme.spacingPx(2),
    [theme.breakpoints.up('md')]: {
      paddingRight: theme.spacingPx(3)
    }
  },
  applyButton: {
    flex: 1,
    display: 'block',
    marginLeft: theme.spacingPx(2),
    minHeight: 42,
    [theme.breakpoints.up('md')]: {
      minHeight: 72
    }
  },
  topControls: {
    justifyContent: 'space-between',
    alignItems: 'baseline',
    paddingBottom: theme.spacingPx(1),
    [theme.breakpoints.up('md')]: {
      display: 'flex'
    }
  },
  viewModeControl: {
    display: 'flex',
    alignItems: 'baseline',
    '& span': {
      marginRight: theme.spacingPx(2)
    }
  },
  layoutOption: {
    padding: `${theme.spacingPx(1)}`,
    opacity: 0.3,
    '&.active': {
      opacity: 1
    }
  },
  sortModeControl: {
    display: 'flex',
    alignItems: 'baseline',
    '& span': {
      marginRight: theme.spacingPx(2)
    }
  },
  sortOption: {
    padding: `${theme.spacingPx(1)}`,
    opacity: 0.3,
    '&.active': {
      opacity: 1
    }
  }
})
