import find from 'lodash/find'
import first from 'lodash/first'
import isEmpty from 'lodash/isEmpty'
import { resolveInternalLinkUrl } from '../lib/resolveLink'
import { useMemo } from 'react'
import useSnapshot from '../store/useSnapshot'
import { getProducts } from '../store/productsSlice'
import isSampleProduct from '../helpers/isSampleProduct'

export function useProductVariantDetails ({ product = {}, variantId, colorVariantId }) {
  return useMemo(() => {
    const variant = find(product?.product?.variants, v => v.id === variantId)
    const colorVariant = colorVariantId
      ? find(product?.colors, c => c.bigCommerceId === colorVariantId)
      : find(product?.colors, c => variant?.option_values?.find(o => o.id === c.bigCommerceId))
    let url = resolveInternalLinkUrl(product)
    if (url && colorVariant) url += `?color=${encodeURIComponent(colorVariant.label)}`
    const category = product?.primaryCategory || first(product?.categories)
    const actualSizes = !isEmpty(product?.sizeList) ? product.sizeList.filter(sizeObject => !isSampleProduct(sizeObject.value) && sizeObject?.disabled !== true) : []
    const activeProductColors = product?.colors?.filter(color => color?.disabled !== true)
    const colorsCount = !isEmpty(activeProductColors) ? activeProductColors.length : 0
    const sizesCount = actualSizes.length

    return {
      productId: product?.bigCommerceId || product?.product?.id,
      productEnquiry: product?.productEnquiry,
      variantId,
      variant,
      productVariants: product?.product?.variants,
      title: product?.title,
      colorVariant: colorVariant,
      colorVariantId: colorVariantId,
      url,
      category,
      productType: 'Product',
      featureImage: colorVariant?.featureImage || product.featuredImage || first(product?.images),
      colorsCount,
      sizesCount
    }
  }, [product, variantId, colorVariantId])
}

export function useProductVariantDetailsFromAlgolia (page) {
  const snap = useSnapshot()
  const products = getProducts(snap)
  const product = useMemo(() => products.find(p => p._id === page.id), [products])
  return useProductVariantDetails({ product, colorVariantId: page.colorVariantId })
}
