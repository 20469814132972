import { useCallback, useContext, useEffect, useRef, useState } from 'react'
import cn from 'classnames'
import { span, theme } from '../../../styles/theme'
import Section from '../../Section'
import StyledLink from '../../StyledLink'
import ResponsiveImage from '../../ResponsiveImage'
import { useStickyElement } from '../../../hooks/useStickyElement'
import use100vhStyle from '../../../hooks/use100vh'
import { createUseStyles } from '../../../helpers/createStyles'
import gsap from 'gsap'
import RotateUpSplitText from '../../RotateUpSplitText'
import { isHomepageAnimatedIn, setHomepageAnimatedIn, getSiteData } from '../../../store/layoutSlice'
import useSnapshot from '../../../store/useSnapshot'
import { isFontsLoaded } from '../../../store/preloadSlice'
import { primaryInput } from 'detect-it'
import { SmoothScrollContext, useScrollListener } from '../../SmoothScrollbar/useSmoothScrollbar'
import useWindowResize from '../../../hooks/useWindowResize'
import useDebouncedCallback from '../../../hooks/useDebouncedCallback'
import { useRouter } from 'next/router'
import defer from 'lodash/defer'
import ProductPrice from '../../ProductPrice'
import useScrollTrigger from '../../../hooks/useScrollTrigger'
import { isMobile } from '../../../lib/helpers'
import Link from '../../Link'
import { useProductVariantDetails } from '../../../hooks/useProductVariantDetails'
import sortBy from 'lodash/sortBy'
import ReactPlayer from 'react-player/vimeo'

const BOTTOM_HEADER_HEIGHT = 58

const HomeHero = ({ data, topBannerRef }) => {
  const styles = useStyles()
  const stickyRef = useRef()
  const blurUpRef = useRef()
  const titleRef = useRef()
  const styledLinkListRef = useRef()
  const contentRef = useRef()
  const heroVideoRef = useRef()
  const snap = useSnapshot()
  const hasAnimatedFirstPageload = isHomepageAnimatedIn(snap)
  const fontsLoaded = isFontsLoaded(snap)
  const siteData = getSiteData(snap)
  const localsRef = useRef({})
  const stickyNavRef = useRef()
  const stickyNavBackgroundRef = useRef()
  const stickyNavContainerRef = useRef()
  const scrollContext = useContext(SmoothScrollContext)
  const [hasAnimated, setHasAnimated] = useState(false)

  useStickyElement(stickyRef, null, 0)

  const {
    desktop_image: desktopImage,
    mobile_image: mobileImage,
    title,
    captions,
    links,
    isProductCaptions,
    productCaption,
    productCaptiontitle,
    featuredVideoDesktop,
    featuredVideoMobile
  } = data

  const height = use100vhStyle() - topBannerRef.current?.clientHeight

  const onTitleTimelineCreated = useCallback((titleTimeline) => {
    localsRef.current.titleTimeline = titleTimeline
  }, [])

  const animateHeroForFirstPageLoad = async () => {
    if (localsRef.current.runningAnimation) return
    localsRef.current.runningAnimation = true
    const logo = document.getElementById('logo')
    const timeline = gsap.timeline()
    timeline.set(logo, { color: theme.colors.black })
    timeline.add(localsRef.current.titleTimeline, 0.4)
    timeline.to([contentRef.current, contentRef.current.children[0]], { y: 0, duration: 1.2, ease: 'quart.inOut' }, '-=0.6')
    timeline.from(homeImageRef.current, { opacity: 0, duration: 1.2, ease: 'expo.inOut' }, '-=1.2')
    timeline.to([blurUpRef.current, titleRef.current, logo], { x: 0, duration: 1.2, ease: 'expo.inOut' }, '-=1.2')
    timeline.to(logo, { color: theme.colors.white, duration: 1.2, ease: 'expo.inOut' }, '-=1.2')
    timeline.to(stickyNavContainerRef.current, { opacity: 1, duration: 0.5, ease: 'sine.inOut' }, '-=0.4')
    timeline.fromTo(contentRef.current.querySelectorAll(`.${styles.links} span`), { x: '0%' }, { x: '160%', duration: 1.2, ease: 'expo.out', overwrite: true }, '-=0.6')
    timeline.eventCallback('onComplete', () => {
      localsRef.current.runningAnimation = false
      timeline.set(logo, { clearProps: 'color' })
      setHomepageAnimatedIn()
      setHasAnimated(true)
    })
  }

  const animateHeroAfterFirstPageLoad = async () => {
    if (localsRef.current.runningAnimation) return
    localsRef.current.runningAnimation = true
    const logo = document.getElementById('logo')
    const timeline = gsap.timeline()
    timeline.set(logo, { color: theme.colors.pageTheme.default.background })
    timeline.fromTo(contentRef.current, { y: '-100%' }, { y: 0, duration: 1.2, delay: 0.5, ease: 'expo.inOut' })
    timeline.fromTo(contentRef.current.children[0], { y: '100%' }, { y: 0, duration: 1.2, ease: 'expo.inOut' }, '-=1.2')
    timeline.fromTo(homeImageRef.current, { scale: 1.1 }, { scale: 1, duration: 1.2, ease: 'expo.inOut' }, '-=1.2')
    timeline.to(stickyNavContainerRef.current, { opacity: 1, duration: 0.5, ease: 'sine.inOut' }, '-=0.5')
    timeline.fromTo([titleRef.current, styledLinkListRef.current], { x: -60 }, { x: 0, duration: 1.4, stagger: 0.2, ease: 'expo.inOut' }, '-=1.4')
    timeline.eventCallback('onComplete', () => {
      localsRef.current.runningAnimation = false
      setHomepageAnimatedIn()
      setHasAnimated(true)
    })
  }

  const initAnimation = useCallback(async () => {
    if (contentRef.current && fontsLoaded) {
      if (!hasAnimatedFirstPageload) {
        animateHeroForFirstPageLoad()
      } else if (!hasAnimated) {
        animateHeroAfterFirstPageLoad()
      } else {
        const logo = document.getElementById('logo')
        gsap.set(logo, { clearProps: 'color' })
      }
    }
  }, [hasAnimated, fontsLoaded, hasAnimatedFirstPageload])

  useEffect(initAnimation, [initAnimation])

  const initHeroVideo = useCallback(async () => {
    const timeline = gsap.timeline()
    timeline.fromTo(heroVideoRef.current, { opacity: 0 }, { opacity: 1, duration: 2, ease: 'expo.inOut' })
  }, [heroVideoRef])

  if (featuredVideoDesktop) {
    useEffect(initHeroVideo, [initHeroVideo])
  }

  const onImageLoaded = useCallback(initAnimation, [initAnimation])

  const onScrollCallback = useCallback(() => {
    const y = scrollContext.current.getScrollY()
    if (stickyNavRef.current) {
      const element = stickyNavRef.current.children[0]
      if (primaryInput !== 'touch') {
        const { windowHeight, windowWidth, navHeight } = localsRef.current
        const navOffset = windowWidth < theme.breakpoints.values.md ? 45 + (topBannerRef.current?.clientHeight || 0) : 28 + (topBannerRef.current?.clientHeight || 0)
        const offset = windowHeight - navHeight - navOffset
        element.style.transform = `translate3d(0, ${y > offset ? y - offset : 0}px, 0)`
      } else {
        element.style.transform = 'translate3d(0, 0, 0)'
      }
      return () => {
        element.style.transform = 'translate3d(0, 0, 0)'
      }
    }
  }, [scrollContext])

  const storeLayout = () => {
    if (stickyNavRef.current) {
      localsRef.current = {
        ...localsRef.current,
        windowHeight: window.innerHeight,
        windowWidth: window.innerWidth,
        navHeight: stickyNavRef.current.clientHeight
      }
      onScrollCallback()
    }
  }

  const { asPath } = useRouter()
  useEffect(() => {
    // We need to calculate the layout after the render as we will not have the portal in the sticky-nav yet
    defer(() => {
      storeLayout()
      onScrollCallback()
    })
  }, [asPath])

  useWindowResize(useDebouncedCallback(storeLayout, 200))
  useEffect(storeLayout, [])
  useScrollListener(onScrollCallback, true, false)

  useEffect(() => {
    if (primaryInput === 'touch' || isMobile()) gsap.set(stickyNavContainerRef.current, { position: 'sticky' })
  }, [])

  const homeImageRef = useScrollTrigger(
    () => ({
      trigger: homeImageRef.current.parentNode,
      start: 'top top',
      end: 'bottom top'
    }),
    (tl, ref) => {
      tl.fromTo(homeImageRef.current, { y: '0%' }, { y: '10%', ease: 'none' })
    }
  )

  const colorLabel = siteData?.colorLabel || 'Color'
  const sizeLabel = siteData?.sizeLabel || 'Size'

  const productDetails = useProductVariantDetails({
    product: productCaption?.product,
    colorVariantId: productCaption?.variantId,
    variantId: sortBy(productCaption?.product?.product?.variants.filter(x => x.calculated_price > 0), ['calculated_price'])?.find((x) => {
      return x.option_values.find(option => option.id === productCaption?.variantId)
    })?.id
  })

  return (
    <>
      <Section fullWidth noBottomMargin>
        <div className={styles.container} style={{ height }}>
          <div className={cn(styles.content, 'animatedIn')}>
            <div className={cn(styles.contentInner, 'animatedIn')}>
              <Section tag='div' className={styles.headlineContainer} grid noBottomMargin>
                <div className={cn(styles.headline, styles.headlineCopy)}>
                  {!hasAnimatedFirstPageload ? (
                  <RotateUpSplitText
                    className={cn(styles.titleCopy, styles.title)}
                    text={title}
                    ref={blurUpRef}
                    initialOpacity={0}
                    duration={1.5}
                    stagger={1.35}
                    onTimelineCreated={onTitleTimelineCreated}
                  />) : title
                  }
                </div>
              </Section>
            </div>
          </div>
          <div className={cn(styles.content)} ref={contentRef}>
            <div className={cn(styles.contentInner)}>
              <ResponsiveImage
                image={desktopImage}
                className={styles.heroImage}
                mobileImage={mobileImage}
                loading='eager'
                fadeIn={false}
                ref={homeImageRef}
                onLoad={onImageLoaded}
              />
              {featuredVideoDesktop && (
                <div
                  className={isMobile() ? styles.heroVideoMobile : styles.heroVideoDesktop}
                  ref={heroVideoRef}
                >
                  <ReactPlayer
                    url={`https://player.vimeo.com/video/${isMobile() && featuredVideoMobile ? featuredVideoMobile : featuredVideoDesktop}`}
                    config={{
                      vimeo: {
                        playerOptions: {
                          background: true,
                          loop: true,
                          muted: true,
                          playsinline: true,
                          quality: '720p'
                        }
                      }
                    }}
                    onPlay={initHeroVideo}
                  />
                </div>
              )}
              <Section tag='div' className={styles.headlineContainer} grid noBottomMargin>
                <div className={styles.headline}>
                  <h1 className={styles.title} ref={titleRef}>{title}</h1>
                  <div className={styles.links} ref={styledLinkListRef}>
                    {links && links.map((link, i) => (
                      <StyledLink
                        key={i}
                        className={cn(styles.link, 'is-caption')}
                        link={link}
                      />
                    ))}
                  </div>
                </div>
              </Section>
              <Section tag='div' className={styles.captions} grid noBottomMargin>
                <div className={styles.captionsContainer}>
                  {!isProductCaptions && captions && captions.map((caption, i) => (
                    <div key={i} className={cn(styles.caption, 'is-caption')}>
                      {caption.split('\n').map((str) => <span key={str}>{str}</span>)}
                    </div>
                  ))}
                  {isProductCaptions && productCaption &&
                    <div className={cn(styles.caption, 'is-caption')}>
                      <Link to={productDetails.url} showText={false}>
                        <span className='dot' />
                        {productCaptiontitle && <span>{productCaptiontitle}</span>}
                        <span>{productCaption.product?.title}</span>
                        <span>{productCaption.product?.colors.filter(x => x.bigCommerceId === productCaption.variantId)[0]?.label}</span>
                      </Link>
                    </div>
                  }
                  {isProductCaptions && productCaption &&
                    <div className={cn(styles.caption, 'is-caption')}>
                      <Link to={productDetails.url} showText={false}>
                        <span>{productCaption.product?.product?.options.filter(x => x.display_name === 'Colour' || x.display_name === 'Color')[0]?.option_values.length} {colorLabel}s</span>
                        <span>{productCaption.product?.product?.options.filter(x => x.display_name === 'Size')[0]?.option_values.length} {sizeLabel}s</span>
                        <ProductPrice prefix='From ' productId={productDetails?.productId} variantId={productDetails?.variantId}/>
                      </Link>
                    </div>
                  }
                </div>
              </Section>
            </div>
          </div>
        </div>
      </Section>
      <div className={styles.stickyNavBackground} ref={stickyNavBackgroundRef} />
      <div className={styles.stickyNav} ref={stickyNavContainerRef}>
        <div ref={stickyNavRef} className={styles.stickyNavMenuContainer}>
          <div id='sticky-nav' className={styles.stickyNavMenu} />
        </div>
      </div>
    </>
  )
}

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    position: 'relative',
    color: theme.colors.white,
    overflow: 'hidden'
  },
  content: {
    position: 'absolute',
    height: `calc(100% - ${BOTTOM_HEADER_HEIGHT}px)`,
    top: 0,
    width: '100%',
    overflow: 'hidden',
    transform: 'translate(0, 100%)',
    '&.animatedIn': {
      transform: 'translate(0, 0%)'
    }
  },
  contentInner: {
    height: '100%',
    width: '100%',
    transform: 'translate(0, -100%)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    '&.animatedIn': {
      transform: 'translate(0, 0%)'
    }
  },
  heroImage: {
    position: 'absolute !important',
    height: '100%',
    width: '100%',
    '> *': {
      paddingTop: '0 !important'
    }
  },
  heroVideoDesktop: {
    position: 'absolute',
    top: 0,
    left: 0,
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100vw',
    height: '100vh',
    opacity: 0,
    ' > div': {
      position: 'absolute',
      width: '1000% !important',
      height: '100vh !important',
      paddingBottom: '56.25%',
      ' > div': {
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        width: '100% !important',
        height: '100% !important'
      }
    },
    '&:after': {
      position: 'absolute',
      content: '""',
      top: 0,
      left: 0,
      width: '1000%',
      height: '100vh'
    }
  },
  heroVideoMobile: {
    position: 'absolute',
    top: 0,
    left: 0,
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100vw',
    height: '100vh',
    opacity: 0,
    ' > div': {
      position: 'absolute',
      width: '1000% !important',
      height: '100vh !important',
      ' > div': {
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        width: '100% !important',
        height: '100% !important'
      }
    },
    '&:after': {
      position: 'absolute',
      content: '""',
      top: 0,
      left: 0,
      width: '1000%',
      height: '100vh'
    },
    '@media screen and (min-aspect-ratio: 9/16)': {
      width: '100vw',
      height: '178vw',
      ' > div': {
        width: '100vw !important',
        height: '1000% !important',
        ' > div': {
          marginTop: 'calc((178vw - 100vh) / 2 * -1)'
        }
      }
    }
  },
  headlineContainer: {
    position: 'relative',
    zIndex: theme.zIndex.imageContent
  },
  links: {
    position: 'absolute',
    left: 0
  },
  link: {
    fontSize: '11px',
    fontWeight: 600
  },
  captions: {
    width: `calc(100% - (2 * ${theme.gutter.md}px))`,
    position: 'absolute',
    bottom: theme.spacing(4),
    right: 0,
    zIndex: theme.zIndex.imageContent
  },
  captionsContainer: {
    gridColumn: '10 / span 3',
    display: 'none',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    [theme.breakpoints.down('lg')]: {
      gridColumn: '9 / span 4'
    },
    [theme.breakpoints.up('md')]: {
      display: 'flex'
    },
    '&:hover .dot': {
      background: 'white'
    }
  },
  caption: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: `${theme.spacing(10)}px`,
    position: 'relative',
    '&:first-child': {
      width: 59 + 32 + 3
    },
    '&:last-child': {
      marginRight: 0
    },
    '& span': {
      fontSize: '10px',
      lineHeight: 1.4
    },
    '& a': {
      color: 'inherit',
      textDecoration: 'none',
      display: 'flex',
      flexDirection: 'column'
    },
    '& .dot': {
      position: 'absolute',
      width: 6,
      height: 6,
      borderRadius: '50%',
      border: '.8px solid currentColor',
      background: 'transparent',
      transition: 'background 0.2s ease-out',
      top: 4,
      left: -16
    }
  },
  headline: {
    gridColumn: '3 / span 6',
    position: 'relative',
    [theme.breakpoints.up('md')]: {
      gridColumn: '5 / span 5'
    }
  },
  title: {
    maxWidth: 540,
    fontSize: 32,
    lineHeight: 1.1,
    transform: `translate(calc(((${span(1, 'sm', theme.gutter.sm * 2, false)}) * -1) - ${theme.gutter.sm}px), 0)`,
    [theme.breakpoints.up('md')]: {
      transform: `translate(calc(((${span(1, 'md', theme.gutter.md * 2, false)}) * -1) - ${theme.gutter.md}px), 0)`,
      fontSize: 56
    }
  },
  innerNavContainer: {
    gridColumn: '5 / span 4',
    [theme.breakpoints.up('md')]: {
      gridColumn: '5 / span 8'
    }
  },
  titleCopy: {
    maxWidth: 540,
    color: theme.colors.black,
    letterSpacing: 0,
    fontFamily: theme.fonts.heading,
    fontWeight: theme.fonts.headingFontWeight,
    marginBottom: `${theme.spacing(2)}px`,
    opacity: 0,
    transform: `translate(calc(((${span(1, 'sm', theme.gutter.sm * 2, false)}) * -1) - ${theme.gutter.sm}px), 0)`,
    [theme.breakpoints.up('md')]: {
      transform: `translate(calc(((${span(1, 'md', theme.gutter.md * 2, false)}) * -1) - ${theme.gutter.md}px), 0)`,
      marginBottom: `${theme.spacing(4)}px`
    }
  },
  stickyNavBackground: {
    position: 'absolute',
    height: 42 + theme.spacing(2),
    backgroundColor: theme.colors.background,
    left: 0,
    marginTop: -(42 + theme.spacing(2)),
    zIndex: theme.zIndex.imageContent,
    width: '100%'
  },
  stickyNav: {
    height: 42 + theme.spacing(2),
    zIndex: theme.zIndex.header + 1,
    position: 'sticky',
    margin: `${-(41 + theme.spacing(2))}px ${theme.gutter.sm}px ${theme.section.marginBottom.sm}px`,
    top: 28,
    justifyContent: 'flex-end',
    alignItems: 'center',
    display: 'flex',
    opacity: 0,
    [theme.breakpoints.up('md')]: {
      position: 'relative',
      top: 52,
      margin: `${-(94 + theme.spacing(2))}px ${theme.gutter.md}px ${theme.section.marginBottom.md}px`,
      marginBottom: 160
    },
    [theme.breakpoints.up('xl')]: {
      top: 52,
      margin: `${-(94 + theme.spacing(2))}px ${theme.gutter.md}px ${theme.section.marginBottom.xl}px`,
      marginBottom: 160
    }
  },
  stickyNavMenuContainer: {},
  stickyNavMenu: {
    width: span(4, 'sm', theme.gutter.sm * 2),
    [theme.breakpoints.up('md')]: {
      width: span(8, 'md', theme.gutter.md * 2)
    }
  }
})

export default HomeHero
