import { useMemo } from 'react'
import forEach from 'lodash/forEach'
import compact from 'lodash/compact'
import isSampleProduct from './isSampleProduct'
import { getSizeVariant } from './productHelper'
import { getCurrencyLocale, getMinMaxPrices } from '../store/pricesSlice'
import sortBy from 'lodash/sortBy'
import uniqBy from 'lodash/uniqBy'
import locales from '../../locales'
import find from 'lodash/find'
import { getShopFilterCategory } from '../store/shopFiltersSlice'
import useProductsWithoutDonations from '../components/Slices/ProductListing/useProductsWithoutDonations'
import useSnapshot from '../store/useSnapshot'
import { getShopAllCollection, getSiteData } from '../store/layoutSlice';
import { useConvertUnitSystem } from '../hooks/useConvertUnitSystem';

export default function getFilters () {
  const snap = useSnapshot()
  const products = useProductsWithoutDonations(snap)
  const shopAllCollection = getShopAllCollection(snap)
  const locale = getCurrencyLocale(snap)
  const siteData = getSiteData(snap)
  const category = getShopFilterCategory(snap)
  const convertSizeString = useConvertUnitSystem()

  const filters = {
    room: [],
    roomStyle: [],
    colour: [],
    size: [],
    price: {
      min: null,
      max: null
    },
    collection: [],
    suitability: [],
    fibre: [],
    inStockOnly: false
  }

  const productsInCategory = useMemo(() => {
    if (!category) return products
    return products.filter(x => x.primaryCategory?._id === category._id || !!find(x.categories, c => c._id === category._id))
  }, [category, products])

  const filterOptions = useMemo(() => {
    const isShopAllCategory = category && category._id === shopAllCollection?._id
    const options = filters

    // Loop over products and get all possible options
    forEach(productsInCategory, productListing => {
      if (productListing.room_tags) options.room = compact([...options.room, ...productListing.room_tags])
      if (productListing.room_style_tags) options.roomStyle = compact([...options.roomStyle, ...productListing.room_style_tags])
      const genericColours = compact(productListing.colors?.map(color => color.generic_colour).filter(val => val) || [])
      options.colour = [...options.colour, ...genericColours]
      if (productListing.sizeList) {
        const actualSizes = productListing.sizeList.filter(sizeObject => !isSampleProduct(sizeObject.value))
        options.size = [...options.size, ...actualSizes]
      }
      const prices = productListing.product?.variants.map(variant => {
        const { option_values: optionValues } = variant
        const variantSize = getSizeVariant(optionValues)
        if (isSampleProduct(variantSize?.label)) {
          return null
        }
        return variant.price
      }).filter(price => price) || []
      const { min: minPrice, max: maxPrice } = getMinMaxPrices(prices, true)
      if (!options.price.min || minPrice < options.price.min) {
        options.price.min = minPrice
      }
      if (!options.price.max || maxPrice > options.price.max) {
        options.price.max = maxPrice
      }
      if (productListing.suitability_tags) options.suitability = compact([...options.suitability, ...productListing.suitability_tags])
      if (productListing.fibre_tags) options.fibre = compact([...options.fibre, ...productListing.fibre_tags])
    })

    options.collection = siteData?.collection.shopFilterCategories

    // Filter results to only unique options
    options.size = sortBy(uniqBy(options.size, x => x.value), x => (locale === locales.countryCodes.us.locale ? convertSizeString(x, 'metric') : x.value))
    options.room = uniqBy(options.room, x => x._id)
    options.roomStyle = uniqBy(options.roomStyle, x => x._id)
    options.colour = uniqBy(options.colour, x => x._id)
    options.collection = isShopAllCategory ? uniqBy(options.collection, x => x._id) : []
    options.suitability = uniqBy(options.suitability, x => x._id)
    options.fibre = uniqBy(options.fibre, x => x._id)

    return options
  }, [productsInCategory, shopAllCollection, locale])

  return filterOptions
}
